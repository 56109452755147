import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#101820', // Color principal
        },
        secondary: {
            main: '#f2aa4c', // Color secundario
        },
        text: {
            primary: '#101820', // Color de texto principal
            secondary: '#101820', // Color de texto secundario
        },
        background: {
            default: '#f2aa4c', // Color de fondo
            paper: '#f2aa4c', // Color de fondo de papel (tarjetas, etc.)
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif' // Familia de fuentes
    },
});

export default theme;
